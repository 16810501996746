import React from 'react';
import { RemoveProduct } from '../../store/Slices/gifts/basketSlice';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import s from './index.module.scss';

const RemoveProductButton = ({ productId, indexes = [], onRemoveProduct = null }) => {
  const dispatch = useDispatch();
  const removeProduct = (e) => {
    if (e.type === 'click') {
      e.preventDefault();

      if (onRemoveProduct) {
        onRemoveProduct(e, productId, indexes);
        return;
      }
      dispatch(RemoveProduct({ productId, indexes }));
      return;
    }

    if (e.code === 'Enter' || e.code === 'Space') {
      if (onRemoveProduct) {
        onRemoveProduct(e, productId, indexes);
        return;
      }
      dispatch(RemoveProduct({ productId, indexes }));
    }
  };
  return (
    <a
      href="#"
      role="button"
      className={s['remove-product-button']}
      onClick={removeProduct}
      onKeyDown={removeProduct}
      data-test="remove-product-button"
    >
      Remove
    </a>
  );
};

RemoveProductButton.propTypes = {
  productId: PropTypes.number.isRequired,
  onRemoveProduct: PropTypes.func,
  indexes: PropTypes.arrayOf(PropTypes.number),
};

export default RemoveProductButton;
