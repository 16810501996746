import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from '../../Link';
import BasketTotal from '../../BasketTotal';
import { basketBannerHide } from '../../../store/Slices/gifts/basketBannerSlice';
import { useRouter } from 'next/router';
import { getCountProducts } from '../../../utils/Gifts/basket';
import GiftItem from './BasketWidgetProductItems/GiftItem';
import Button from '../../Button';
import { RemoveProduct } from '../../../store/Slices/gifts/basketSlice';
import { isSantaLetterProduct } from '../../../utils/Gifts/products';

import styles from './index.module.scss';

const BasketBanner = () => {
  let currentTimeout = null;
  const visible = useSelector((state) => state.basketBanner.isVisible);
  const product = useSelector((state) => state.basketBanner.product);
  const basketProducts = useSelector((state) => state.basket.products);

  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    if (visible) {
      if (currentTimeout) {
        clearTimeout(currentTimeout);
      }
      // Hide message after given amount of seconds.
      currentTimeout = setTimeout(() => dispatch(basketBannerHide()), 6000);
    }
    return () => {
      if (currentTimeout) {
        clearTimeout(currentTimeout);
      }
    };
  }, [visible]);

  useEffect(() => {
    dispatch(basketBannerHide());
  }, [router]);

  if (!visible) {
    return null;
  }

  // Cover case when user already on the basket page.
  if (router.asPath.match(/^\/basket|\/cards|\/checkout$/)) {
    return null;
  }

  const count = getCountProducts(basketProducts);
  const products = basketProducts.filter((item) => item.id === product.id);
  const items = products.map((item) => (
    <GiftItem
      key={item.id}
      product={item}
      handleDeleteClick={() => {
        // Remove only the last added product.
        // For example: remove 1 Pig even if we already have 3 Pig products in the basket.
        if (
          isSantaLetterProduct(item.data) ||
          item.data.type === 'gift__normal' ||
          item.data.type === 'gifts_bundle'
        ) {
          dispatch(RemoveProduct({ productId: item.id, indexes: [item.quantity - 1] }));
        } else {
          dispatch(RemoveProduct({ productId: item.id }));
        }
        clearTimeout(currentTimeout);
        dispatch(basketBannerHide());
      }}
      withLinks
    />
  ));

  return (
    <div
      className={styles['basket-banner']}
      onMouseEnter={() => clearTimeout(currentTimeout)}
      onMouseLeave={() => {
        currentTimeout = setTimeout(() => dispatch(basketBannerHide()), 2000);
      }}
      data-test="basket-banner"
    >
      <i
        className={`material-icons close ${styles['basket-banner__close']}`}
        onKeyPress={() => dispatch(basketBannerHide())}
        onClick={() => dispatch(basketBannerHide())}
      />
      <div className={styles['basket-banner__message']}>
        <div className={styles['basket-banner__checked-icon-success']} />
        {product.title} was added to your basket!
      </div>
      <div className={styles['basket-banner__content']}>
        <div className={`${styles['basket-banner__products']} basket-banner-products`}>{items}</div>

        <div className={styles['basket-banner__total']}>
          <div className="toolbar-title">
            Basket total ({count} {count === 1 ? 'item' : 'items'}):
          </div>
          <div className="amount">
            <BasketTotal />
          </div>
        </div>

        <Link href="/basket">
          <Button isBlock className={styles['basket-banner__go-to-basket']}>
            GO TO BASKET
          </Button>
        </Link>
        <Link href="/">
          <a className={styles['basket-banner__browse-more-gifts']}>BROWSE MORE GIFTS</a>
        </Link>
      </div>
    </div>
  );
};

export default BasketBanner;
