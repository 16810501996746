import React from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import BasketTotal from '../../BasketTotal';
import GiftItem from './BasketWidgetProductItems/GiftItem';
import FreeProductsOfferings from '../../FreeProductsOfferings';
import Link from '../../Link';
import { getCardsForProduct, getCountProducts } from '../../../utils/Gifts/basket';
import Button from '../../Button';
import { isSantaLetterProduct } from '../../../utils/Gifts/products';
import SantaLetterItem from './BasketWidgetProductItems/SantaLetterItem';

import styles from './index.module.scss';

const BasketWidgetDropdown = () => {
  const products = useSelector((state) => state.basket.products);
  const basketType = useSelector((state) => state.basket.type);
  const router = useRouter();

  if (router.asPath.match(/^\/basket|\/cards|\/checkout$/)) {
    return null;
  }

  // Don't render anything when there are
  // no products in the basket.
  if (!products.length) {
    return null;
  }

  // Get an array with the list of renderable products.
  const items = [];
  [...products]
    .reverse()
    .slice(0, 4)
    .forEach((product, index) => {
      if (isSantaLetterProduct(product.data)) {
        const santaLetterCards = getCardsForProduct(product, 'santaLetters');
        santaLetterCards.forEach((item, index) =>
          items.push(
            <SantaLetterItem key={`${product.id}-${index}`} item={item} cardIndex={index} />,
          ),
        );
      } else {
        items.push(<GiftItem key={`${product.id}-${index}`} product={product} withLinks />);
      }
    });

  const count = getCountProducts(products);

  return (
    <div className="cart-dropdown" data-test="basket-widget-dropdown">
      {items}

      <div className="total">
        <div className="toolbar-title">
          Basket total ({count} {count === 1 ? 'item' : 'items'}
          ):
        </div>
        <div className="amount">
          <BasketTotal />
        </div>
      </div>

      <div className="cart-link">
        <Link href="/basket">
          <Button isBlock className={styles['basket-banner__go-to-basket']}>
            GO TO BASKET
          </Button>
        </Link>
      </div>
      {basketType === 'gift' && <FreeProductsOfferings variant="basket-widget" />}
    </div>
  );
};

export default BasketWidgetDropdown;
